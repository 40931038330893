<template>
<div class="customjs">
  <div v-if="landing.profi">
    <label style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;" class="label">Custom scripts settings</label>
        <label class="label">Add your custom js to landing</label>
        <p style="margin-bottom: 15px;">Add all your functions to this textarea</p>
        <b>&lt;script&gt;</b>

        <textarea
            style="display:block;height:200px;width: 100%;border:1px solid #eee;border-radius: 15px;padding:5px;"
            v-model="landing.advanced.global.custom_scripts"
            rows="4"
            placeholder="Write your custom JS"
        ></textarea>
        <b>&lt;script/&gt;</b>
        </div>
  <div style="display: flex;padding:20px 0;" v-if="!landing.profi">
    <v-icon color="#cccccc" size="60px" style="margin-right: 20px;">mdi-lock</v-icon>
    <h1 class="constructor__heading" style="font-weight: normal;font-size: 16px;">This feature is available on the Pro plans.
      <br>To activate the tariff <a @click="billing">click here</a></h1>
  </div>
  <PopupTariff ref="PopupTariff"/>
</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: "CustomJsPage",
  computed:{
    ...mapState(['landing']),
  },
  components:{
    PopupTariff: () => import("../../Popup/PopupTariff"),
  },
  methods:{
    billing(){
      let obj = this.$store.state.objects.list.find(x => +x.id === +this.$route.params.id);
      this.$refs.PopupTariff.open(obj);
    },
    setChanges(){
      this.$store.commit('setLanding',this.landing);
    }
  }
}
</script>

<style scoped lang="scss">
.v-textarea textarea {
  line-height: 40px;
}
</style>
